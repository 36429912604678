// 例如 next-i18next.config.js
const path = require('path');

module.exports = {
    i18n: {
        defaultLocale: 'en',
        locales: ['en', 'es', 'pt', 'ja', 'ko', 'de', 'it'],
    },
    localesPath: path.resolve('./public/locales'),
};
